/* Not sure if this is used, maybe by me_js ? */
    .ArtifactMedia header {
        display: flex;
        margin-top: 1em;
    }
    .ArtifactMedia h1 {
        margin-top: 0;
    }
    .ArtifactMedia canvas{
        margin: auto;
        display: block;
    }
    .ArtifactMedia img {
        margin: auto;
        text-align: center;
        display: block;
        width: 90%;
        height: auto;
    }
    .ArtifactMedia section {
        border-bottom: 1px solid gray;
        padding-bottom: .5em;
        margin-bottom: .5em;
    } 
/* .mediaAddDiv { */
/*     margin-bottom: 3em; */
/*     display: block; */
/*     text-align: center; */
/* } */
.videoEmbed {
    text-align: center;
    margin: auto;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    width: 90%;
}
.videoEmbed iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.mejs__player {
    height: 40px;
}
