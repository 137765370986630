.Sidebar {
    background-color: var(--MAIN_YELLOW);
    color: #000;
    display: flex;
    min-width: 235px;
    padding: 1em 1em 2em 1em;
    flex-wrap: wrap;
    gap: 0.1em;
    flex-direction: column;
}

    /* @media (width < 50em) { */
    /*     flex-direction: row !important; */
    /*     justify-content: space-around; */
    /*     .toggler-choices { */
    /*         flex-direction: row !important; */
    /*         justify-content: space-evenly; */
    /*     } */
    /*     .text-search-input { */
    /*         flex-grow: 1; */
    /*     } */
    /*     .SearchButton { */
    /*         order: 1; */
    /*         flex-grow: 1; */
    /*     } */
    /* } */

    .Sidebar fieldset {
        margin: 0;
        padding: 0;
        border:0;
        border-bottom: 1px black;
    }

    .Sidebar legend {
        font-weight: bold;
        text-align: center;
        margin-bottom: .5em;
        font-size: 1.1em;
        border: 0;
    }

    .Sidebar .text-search-input {
        background-color: white;
        font-size: 1em;
        padding: 0.75em;
    }

    .Sidebar .toggler-choices {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.4em;
        column-gap: 0.9em;
        flex-direction: column;

        /* justify-content: space-evenly; */
        /* flex-direction: row; */
    }

    .Sidebar .choice {
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        gap: .5em;
    }

    .Sidebar .all-choice .toggle-label { text-decoration: underline;}

    .Sidebar .toggle-label { font-weight: bold; }

    .Sidebar .choice-label { font-weight: bold; }

    .Sidebar .Search-fields {
        display: block;
    }

    .Sidebar .Search-fields h3 { text-align: right; }

    .Sidebar .Search-fields.highlight {
            text-align: right;
            margin: 0;
        }

    .Sidebar .Search-fields.highlight p { margin: 0.25em; }

    .Sidebar h1, .Sidebar h2 { font-weight: bold; }

    .Sidebar h1 { font-size: 1.5em; }

    .Sidebar h2 { font-size: 1.3em; }

    .Sidebar hr { border-color: #000; }
