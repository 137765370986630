.Route {
    background-color: #FFF;
    height: 100%;
    margin: 0px auto 0px 0px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
}

    .Route .content {
        max-width: 75ch; 
        padding: 0.5em 1em 1em 2em;
        overflow: hidden;
        flex: 3;
        margin: 0px 0px 20px 0px;
    }
