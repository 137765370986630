.footer {
    background-color: var(--MAIN_BG);
    color: #FFF;
    bottom: 0;
    max-width: 1450px;
    margin: auto;
    padding: 1em;
    font-size: 0.9em;
    display: flex;
}

    .footer a {
        color: var(--MAIN_YELLOW);
        text-decoration: none;
        font-size: 0.9em;
    }

    .footer a:hover { text-decoration: underline; }

    .footer .footerText {
        flex: 7;
    }

    .footer .footerText p a {
            flex: 1;
            padding-right: 15px;
        }

    .footer .footerImage {
        flex:1;
        display: block;
        float: right;
    }

    .footer .footerImage img {
            padding-top: 1em;
            max-height: 100px;
            padding-left: 5px;
        }

