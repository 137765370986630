.toc {
    padding: 10px 0px 10px 25px;
    border-radius: 25px;
    background-color: lightgray;
    width: 40%;
    color: black;
    float: left;
    margin: 0px 25px 10px 0px;
}
#NavBar {
    flex: 2 0 auto;
    max-width: 100vw;
}
/* height: 11em; */
#NavBar ul {
        display: flex;
        flex-wrap: nowrap;
        -webkit-margin-before: 0em;
        -webkit-padding-start: 0px;
        height: 100%;
        margin: 0;
    }
#NavBar li {
        display: inline-flex;
        flex-grow: 1;
    }
#NavBar .option {
        font-size: 1.3em;
        text-decoration: none;
        font-weight: bold;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
#NavBar .option a {
            color: white;
            padding: 0.5em;
            text-decoration: none;
            align-items: flex-end;
            display: flex;
            justify-content: center;
        }
#NavBar .option .active {
            background: #2f6665;
            color: var(--MAIN_YELLOW);
            pointer-events: none;
        }
#NavBar .option:hover {
            color: #fff;
            text-decoration: underline;
        }
