.SearchResult {
    display: flex;
    /* display: inline-block; */ 
    padding: 5px;
    flex-wrap: wrap;
    border-bottom: 1px solid gray;
    margin-bottom: .5em;
}

    .SearchResult header {
        display: flex;
        flex-grow: 1;
    }

    .SearchResult header .labels {
            /* max-width: 250px; */
            display: inline;
            font-weight: bold;
            text-align: center;
        }

    .SearchResult .articleTitle {
        display: inline;
        line-height: 1.5;
        font-size: larger;
    }

    .SearchResult .ResultMatches { margin: 4px 1em; }

    .SearchResult .MatchKey { text-decoration: underline; }

    .SearchResult .MatchSpans { margin-left: 1em;}

    .SearchResult .MatchKeySpans {
        padding: 1px 2px;
        background-color: beige;
    }

    .SearchResult .MatchKeySpans span {
            line-height: 1.5em;
        }

    .SearchResult .MatchKeySpans span.Matched {
                padding: 2px 2px;
                background-color: yellow;
            }

.keywords {
    display: flex;
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.keyword {
    list-style-type: none;
    
    margin: 0.1em 0;
    background-color: lightyellow;
    padding: 0.1em 0.2em;

    border-radius: .5rem;
    /* border: 1px solid lightgray; */
}

.IconKindWrapper {
    /* float: left; */
    display: flex;
    flex-direction: column;
    flex-basis: min-content;
    text-decoration: none;
}

.IconKindWrapper .kindIcon {
        display: flex;
        flex-basis: min-content;
        flex-direction: column;
        text-decoration: none;
        padding: 0.5em;
        margin: 0.25em;
    }

.IconKindWrapper .kindIcon .kindName {
            text-align: center;
            font-weight: bold;
            color: black;
        }

.IconKindWrapper .kindIcon img {
            margin-bottom: 0.5em;
            flex-basis: min-content;
            /* max-width: 250px; */
            /* display: block; */
        }
