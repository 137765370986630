.ArtifactTypePicker .seperator { height: 120px; }
    .ArtifactTypePicker h1 {
        width: 150px;
        padding: 0.5em;
        border-radius: 25px;
        margin: 10px;
        display: block;
    }
    .ArtifactTypePicker .highlight {
        height: 100px;
        width: 150px;
        padding: 0.5em;
        border-radius: 25px;
        margin: 10px;
        display: block;
        background-color: var(--MAIN_YELLOW);
    }
    .ArtifactTypePicker .highlight h3 { white-space: nowrap; }
    .ArtifactTypePicker .highlight .type-icon {
            height: 50px;
            display: block;
            padding-top: 10px;
            margin: auto;
        }
.documentPreview h3 { text-align: center; }

.EntryProperty {
    display: contents;
}
dl {
  display: grid;
  grid-gap: 4px 16px;
  grid-template-columns: max-content;
}
dt {
  font-weight: bold;
}

dd {
  margin: 0;
  grid-column-start: 2;
}
