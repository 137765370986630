/* .searchButton { */
/*     margin: 1em; */
/*     border-radius: 25px; */
/* } */

.SearchButton {
    font-family: 'Montserrat', sans-serif;
    color: #FFF;
    font-size: 1.2em;
    border-color: var(--MAIN_YELLOW);
    background-color: var(--BUTTON_GREEN);
    padding: 10px 40px;
    border-radius: 25px;
}

.SearchButton :disabled {
        background-color: gray;
    }

.text-search-input {
    border-color: var(--MAIN_YELLOW);
    background-color: lightgrey;
}

.horizontal-seperator {
    border: lightgrey;
    border-style: solid;
    border-width: thin;
    margin-top: 10px;
}

.vertical-seperator { height: 80% }
