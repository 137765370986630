html, #app, #app>div, body {
    height: 100%;
    margin: auto;
}
body {
    background: var(--MAIN_BG);
    font-family: 'Montserrat', sans-serif;
    color: #000;
    margin: 0px;
    height: 100%
}

/* #root { height:100%; } */
#root {
    height: 100% !important;
    max-width: 1200px;
    margin: auto;
}
.main {
    background-color: #FFF;
    margin: auto;
    overflow: hidden;
}

h1 { font-weight:100; }
p { font-size: 1.2em; }

.error { color: red; }
.indentation { margin-left: 30px; }

