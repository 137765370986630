button .download {
    text-align: left;
    padding: 0px 0px 10px 0px;
}

    button .download p { padding-bottom: 1em; }

    button .download a {
        font-family: 'Montserrat', sans-serif;
        color: #FFF;
        font-size: 1.2em;
        border-color: var(--MAIN_YELLOW);
        background-color: var(--BUTTON_GREEN);
        padding: 10px 40px;
        width: 90%;
        border-radius: 25px;
        margin: 10px;
        text-decoration: none;
    }

    button .download.center { text-align: center; }
