.about img {
        max-width: 45%;
        max-height: auto;
        display: block;
        float: right;
        padding: 0.5em 1em 0.5em 1em;
    }
.contactText p {
        padding: 0px;
        margin: 0;
        padding-left: 40px;
    }
