.breadcrumb.menu {
        padding-bottom: 4px;
    }

        .breadcrumb.menu ul li a:hover { text-decoration: underline; }

        .breadcrumb.menu .admin {
            padding-bottom: 4px;
            text-align: center;
        }

        .breadcrumb.menu .admin ul {
                padding-left: 0px;
            }

        .breadcrumb.menu .admin ul li { display: inline; }
    .breadcrumb ul {
        padding-left: 0px;
    }
    .breadcrumb ul li { display: inline; }
    .breadcrumb .item {
        color: #FFF;
        font-size: 1.2em;
        background-color: var(--BUTTON_GREEN);
        margin-right: 1em;
        padding: 10px 20px;
        border-radius: 25px;
        text-decoration: none;
        display: inline-block;
        margin: 4px;
    }
    .breadcrumb .item.active {
            color: #000;
            background-color: var(--MAIN_YELLOW);
        }
