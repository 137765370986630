.Home .search {
        justify-content: center;
        padding: 2em 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* .form { */
		    /*     font-size: 1.2em; */
		    /*     display: flex; */
		    /*     justify-content: center; */
		    /*     padding-top: 2em; */
        /* } */
    }
        
        .Home .search .text-search-input {
            width: max(min(700px, 85%), 90%);
            font-size: 1.5em;
            padding: 15px;
            border-color: black;
            border-radius: 30px;
        }
        
        .Home .search .seperator { border: 1px solid lightgray; }
        
        .Home .search .SearchButton {
            width: min(max(400px, 70%), 90%);
        }
        
        .Home .search .eras .choice-label { max-width: 6.5ch; }
        
        .Home .search .types .highlight { background-color: var(--MAIN_YELLOW); }
        
        .Home .search .toggler {
            display: flex;
            margin-left: 2em;

            /* temporarily disable responsiveness */
            /* flex-wrap: wrap; */

            flex-grow: 10;
            justify-content: space-evenly;
            border: 0;
            gap: 0.5em 1em;
        }
        
        .Home .search .toggler legend {
                /* legend elements are have styling limitations
 that make all this necessary */
                float: left;
                font-size: 2em;
                max-width: 5ch;
                text-align: center;
                margin: auto 0;
            }
        
        .Home .search .toggler .toggler-choices {
                display: contents;
            }
        
        .Home .search .toggler .toggler-label {
                flex-direction: row;
                display: flex;
                gap: 1em;
                flex-grow: 1;
                flex-shrink: 1;
            }
        
        .Home .search .toggler .all-choice {
                display: flex;
                flex-direction: row;
            }
        
        .Home .search .toggler .icon-choice {
                border-radius: 15px;
                flex-grow: 1;
                flex-shrink: 1;
                overflow:hidden;
                height: 100px;                
            }
        
        .Home .search .toggler .icon-choice .type-icon {
                    height: 50px;
                    display: block;
                    margin: auto;
                }
        
        .Home .search .toggler .choice {
                display: flex;
                flex-direction: column;                
                align-items: center;
                text-align: center;
                padding: 0.1em 0.6em;
                /* width: 150px; */
            }
        
        .Home .search .toggler .choice-label, .Home .search .toggler .toggle-label {
                font-weight: bold;
                font-size: 1.2em;
            }
        
        .Home .search .Search-fields {
            display: flex;
            margin: 1em 4em 1em 4em;
        }
        
        .Home .search .Search-fields .Search-field {}
        
        .Home .search .Search-fields .highlight {
	              text-align: center;
                border-radius: 25px;
            }
        
        .Home .search hr {
            width: 90%;
            border-color: lightgrey;
            border-style: solid;
            border-width: thin;
        } 

/* & h1 { */
/*     width: 90px; */
/*     padding: 0.25em 0em 0em 0.5em; */
/*     margin: 10px; */
/*     border-radius: 25px; */
/*     display: block; */
/* } */
