.header {
    display: flex;
    flex-wrap: wrap;
    color: #FFF;
    overflow: hidden;
    margin: 0;
}

    .header a { flex: 1 0 auto; }

    .header img {
        text-align: left;
        float: left;
        padding: 1.5em 3.75em 0em 2em;
        margin-bottom: 1em;
        width: 280px
    }

